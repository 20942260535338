import { useRef } from "react";
import EachCarousel from "./eachCarousel";
import muslim from "../../../../assets/images/muslim.png";
import agric from "../../../../assets/images/agric.png";
import health from "../../../../assets/images/health.png";
import plane from "../../../../assets/images/plane.png";
import roadPath from "../../../../assets/images/Frame 1968.png";
import "./style.scss";
import Slider from "react-slick";
import { PopupModal } from "react-calendly";
import { useState } from "react";
const Hero = () => {
  const sliderRef = useRef();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToShow: 1,
    fade: true,
    // slidesToScroll: 1,
  };

  const carousel1 = [
    {
      image: muslim,
      text: "Businesses",
    },
    {
      image: agric,
      text: "Agriculture",
    },
    {
      image: plane,
      text: "Logistics",
    },
    {
      image: health,
      text: "Medicine",
    },
  ];

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");

  const handleClick = (email) => {
    console.log(email.current.value);
    setEmail(email.current.value);
    setOpen(true);
  };

  return (
    <div className="pd_hero">
      <div className="carobox">
        <Slider ref={sliderRef} {...settings}>
          {carousel1.map((item) => {
            return <EachCarousel item={item} handleClick={handleClick} />;
          })}
        </Slider>
      </div>

      {/* Calendly Modal */}
      <PopupModal
        url="https://calendly.com/e-osigweh/demo_session_"
        prefill={{
          email,
          firstName: "",
          lastName: "",
          name: "",
        }}
        onModalClose={() => setOpen(false)}
        open={open}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
      />
    </div>
  );
};

export default Hero;

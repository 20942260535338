import "./style.scss";
import faqimg from "../../../../assets/icons/faqimg.svg";
import cross from "../../../../assets/icons/cross.svg";
import mail from "../../../../assets/icons/mail.png";
import phone from "../../../../assets/icons/call.png";
import { useEffect, useState } from "react";
const FAQ = ({faqRef}) => {
	const faqList = [
		{
			question: "What data can I monitor on the Enterprise application?",
			id: "1",
			answer: "Temperature and humidity level of your product storage environment as well as the location of your product.  ",
		},
		{
			question: "What products can I track with the enterprise?",
			id: "2",
			answer: "Vaccines, Medical samples, Blood, Dairy products, FMCGs, Pharmaceutical products as well as every other cold-chain or ambient product.",
		},
		{
			question: "Do I need Dataloggers to use the Enterprise? ",
			id: "3",
			answer: "Yes. The enterprise software uses IoT-enabled Dataloggers to monitor your product’s storage condition.",
		},
		{
			question:
				"Does my enterprise subscription come with free dataloggers?",
			id: "4",
			answer: "Yes. All subscription plans come with an allocated number of free devices.",
		},
		{
			question: "How many users can I have on my enterprise account?",
			id: "5",
			answer: "You can add as many users as you'd like to your enterprise account.",
		},
	];

	const [selected, setSelected] = useState(null);
	const [modal, setModal] = useState(false);
	const toggle = (i) => {
		if (selected === i) {
			setSelected(null);
		} else {
			setSelected(i);
		}
	};

	useEffect(() => {
		if (modal) {
			var x = window.scrollX;
			var y = window.scrollY;
			window.onscroll = function () {
				window.scrollTo(x, y);
			};
		} else {
			window.onscroll = null;
		}
	}, [modal]);

	const sendMessage = (e) => {
		e.preventDefault();
		setModal(!modal);
	};

	const closeModal = (e) => {
		e.preventDefault();
		setModal(false);
	};

	return (
		<div className="pd_faq" ref={faqRef}>
			{modal && <div className="overlay" onClick={closeModal} />}
			<p className="title_faq">
				Frequently <br /> asked questions
			</p>
			<div className="faq_flex">
				<div className="left">
					<div className="wrapper">
						<div className="accordion">
							{faqList.map((item, i) => {
								return (
									<div key={i} className="item">
										<div
											className="title"
											onClick={() => {
												toggle(i);
											}}>
											<p className="question">
												{item.question}
											</p>
											<img src={cross} alt="cross" />
										</div>
										<div
											className={`content ${
												selected === i ? "show" : ""
											}`}>
											<p>{item.answer}</p>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
				<div className="right">
					<img src={faqimg} alt="faqimg" />
					<p className="more">Do you have more questions?</p>
					<p className="location">
						Contact information <br /> Location - {"15a Ajanaku Street, Awuse Estate, Opebi Ikeja."}
						<br /> Email - sales@gricd.com
						<br /> Phone - 08087263522
					</p>
					<p>
						You can also speak with our <br /> consultant for FREE
					</p>
					<div className="relative">
						<button className="send" onClick={sendMessage}>
							Schedule a FREE Consultation
						</button>

						{modal && (
							<ul className="modal">
								<li>
									<a
										href="mailto:saas@gricd.com"
										className="link"
										onClick={() => setModal(false)}>
										<img src={mail} alt="mail" />
										Email
									</a>
								</li>
								<li>
									<a
										href="tel:+234 814 298 1261"
										className="link"
										onClick={() => setModal(false)}>
										<img src={phone} alt="phone" />
										Call
									</a>
								</li>
							</ul>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default FAQ;

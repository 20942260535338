import veg from "../../../../assets/images/veg.png";
import plane3 from "../../../../assets/images/plane3.png";
import health3 from "../../../../assets/images/health3.png";
import arrow from "../../../../assets/icons/arrow.svg";
import "./style.scss";
import Slider from "react-slick";
import { useRef } from "react";
const Application = () => {
  const sliderRef = useRef();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 5000,
    // autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="pd_application">
      <p className="title">Application of our technology</p>
      <div className="black_cover">
        <div className="cover_carousel">
          <img
            src={arrow}
            onClick={() => {
              sliderRef.current.slickPrev();
            }}
            className="arr"
            alt="arrow"
          />
          <div className="cover_all_carousel">
            <Slider ref={sliderRef} {...settings}>
              <div className="each_carousel">
                <div className="left_side">
                  <p className="main_text">Agriculture</p>
                  <p className="desc">
                    Agro products are largely perishable, the enterprise <br />
                    software is used by agricultural businesses to ensure <br />{" "}
                    proper storage of their harvests and produce as well <br />{" "}
                    as ensure the quality of their processes.
                  </p>
                  {/* <button>Get Started Now</button> */}
                </div>
                <img src={veg} alt="veg" className="right_img" />
              </div>
              <div className="each_carousel">
                <div className="left_side">
                  <p className="main_text">Logistics</p>
                  <p className="desc">
                    The enterprise software makes it easy for logistics <br />
                    businesses within the supply chain to <br /> ensure all
                    ambient and cold chain products are optimally stored and
                    shipped.
                  </p>
                  {/* <button>Get Started Now</button> */}
                </div>
                <img src={plane3} alt="veg" className="right_img" />
              </div>
              <div className="each_carousel">
                <div className="left_side">
                  <p className="main_text">Health Organisations</p>
                  <p className="desc">
                    The enterprise software is used by organizations in the{" "}
                    <br />
                    health sector to monitor and ensure the quality and
                    integrity <br /> of drugs, vaccines, medical samples, blood,
                    reagents etc.
                  </p>
                  {/* <button>Get Started Now</button> */}
                </div>
                <img src={health3} alt="veg" className="right_img" />
              </div>
            </Slider>
          </div>
          <img
            src={arrow}
            onClick={() => {
              sliderRef.current.slickNext();
            }}
            className="sec arr"
            alt="arrow"
          />
        </div>
      </div>
    </div>
  );
};

export default Application;

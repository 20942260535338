/* eslint-disable no-unused-vars */
import StepMovement from "../../components/StepMovement";
import Checkbox from "@mui/material/Checkbox";
import "./style.scss";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import bdown from "../../assets/icons/bdown.svg";
import logo from "../../assets/icons/logo.svg";
import successIcon from "../../assets/images/Check icon.jpg";
import { formatCurrency } from "../../utils";
import PaystackPop from "@paystack/inline-js";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import ArrowBack from "@mui/icons-material/ArrowBack";
// import { toast } from "react-toastify";

const Summary = () => {
  const [checked, setChecked] = useState(true);
  const [success, setSuccess] = useState(false);
  const [showloader, setShowloader] = useState(false);
  const handleChange = (e) => {
    setChecked(e.target.checked);
  };

  const navigate = useNavigate();
  const { state } = useLocation();

  const { state: planInfo, data } = state;
  const baseUrl = "https://gricd-africa.herokuapp.com/v2/enterprise";
  const { email, company, first_name, last_name, phone } = data;


  const onSubmit = () => {
    let key = "";
    const devMode = process.env.NODE_ENV;
    // if (devMode === "production") {
    //   key = process.env.REACT_APP_LIVE_KEY;
    // } else key = process.env.REACT_APP_TEST_KEY;
    key = process.env.REACT_APP_TEST_KEY;

    const paystack = new PaystackPop();
    paystack.newTransaction({
      key,
      email,
      amount: planInfo.totalPrice * 100,
      onSuccess: (transaction) => {
        // Payment complete! Reference: transaction.reference
        setSuccess(true);
        setShowloader(true);
        proceed(transaction.reference);
      },
    });
  };

  useEffect(() => {
    if (!state) return navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const proceed = async (response) => {
    const payload = {
      pricingid: planInfo.id,
      amountPaid: planInfo.totalPrice.toString(),
      paystack: response,
      months: planInfo.totalMonths,
      addons: planInfo.addonList.map(({ _id }) => _id),
      email,
      full_name: `${first_name} ${last_name}`,
      company_name: company,
      phone,
    };
    await axios
      .post(`${baseUrl}/saasSub`, payload)
      .then((res) => {
        setShowloader(false);
        // setTimeout(() => {
        //   navigate("/");
        // }, 2000);
      })
      .catch((err) => {
        // console.log(err, "error");
        toast.error(err.response.data.message);
        setShowloader(false);
        // setTimeout(() => {
        //   setSuccess(false);
        // }, 2000);
      });
  };

  const back = () => {
    navigate("/checkout", {
      state,
    });
  };

  const goToHome = () => {
    navigate("/");
  };

  return (
    <React.Fragment>
      {success ? (
        <div className="success" style={{ padding: "5rem 0" }}>
          {showloader ? (
            <div>
              <CircularProgress color="success" size={"5rem"} />
            </div>
          ) : (
            <>
              <div className="success success_container">
                <img
                  src={successIcon}
                  alt=""
                  style={{ marginBottom: "25px" }}
                />
                <p>Payment Successful</p>
                <a
                  href="http://enterprise.gricd.africa/login"
                  target="_self"
                  className="w-100"
                >
                  <button
                    className="pay btn back-to-checkout-btn"
                    // onClick={() => {
                    //   navigate("/");
                    // }}
                    style={{ marginTop: "80px", marginBottom: "25px" }}
                  >
                    Login to Gricd Enterprise
                  </button>
                </a>

                <p className="back back_to_web_text" onClick={goToHome}>
                  <ArrowBack />
                  Go back to the website
                </p>
              </div>
            </>
          )}
        </div>
      ) : !state ? (
        "hello"
      ) : (
        <div className="pd_checkout">
          <div className="cover_checkout">
            <StepMovement step={3} />
            <div className="bill_box">
              <div className="each_pack">
                <div className="top_pack">
                  <p>Package</p>
                  <img src={bdown} alt="down" />
                </div>
                <div className="text_flex">
                  <p>
                    {planInfo?.plan?.name} /{" "}
                    {planInfo?.duration === "annualCost"
                      ? "Annually"
                      : "Monthly"}
                  </p>
                  <p>
                    {planInfo?.currency}{" "}
                    {formatCurrency(
                      planInfo?.plan[planInfo?.duration].substring(1)
                    )}
                  </p>
                </div>
              </div>

              <div className="each_pack">
                <div className="top_pack">
                  <p>Add-Ons</p>
                  <img src={bdown} alt="down" />
                </div>
                <div className="text_flex">
                  <p>{planInfo?.addonList?.length} items in cart</p>
                  <p>
                    {planInfo?.currency}
                    {formatCurrency(planInfo?.totalAddon)}
                  </p>
                </div>
              </div>

              <div className="each_pack sum">
                <div className="top_pack">
                  <p>Check out Summary</p>
                  <img src={bdown} alt="down" />
                </div>
                <div className="line"></div>
                <div className="text_flex total">
                  <p>Total</p>
                  <p>
                    {planInfo?.currency} {formatCurrency(planInfo?.totalPrice)}
                  </p>
                </div>
              </div>
              {/* <div className="check_flex summary_check">
                <Checkbox checked={checked} onChange={handleChange} />
                <p>
                  Please check to acknowledge our{" "}
                  <Link onClick={(e) => e.preventDefault()} to="/">
                    {" "}
                    Privacy & Terms Policy
                  </Link>
                </p>
              </div> */}
              <button className="pay btn" onClick={onSubmit}>
                Pay {planInfo?.currency} {formatCurrency(planInfo?.totalPrice)}
              </button>

              <div className="back_container">
                <p className="back" onClick={() => back()}>
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/151/151846.png"
                    alt="back"
                    className="back_icon"
                  />
                  Back to Checkout
                </p>
              </div>
              <img src={logo} alt="logo" className="logo sum" />
            </div>{" "}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Summary;

import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import "./style.scss";
import About from "./widgets/About";
import Application from "./widgets/Application";
import Choose from "./widgets/Choose";
import Client from "./widgets/Customer";
import FAQ from "./widgets/Faq";
import FifthSection from "./widgets/FifthSection";
import Hero from "./widgets/Hero";
import Plan from "./widgets/Plan";
import Review from "./widgets/Review";
import Serve from "./widgets/Serve";
import axios from "axios";
import SoftwareInfo from "./widgets/SoftwareInfo";

const Home = ({ refObj, executeScroll }) => {
	const { aboutUsRef, plansRef, contactUsRef, faqRef, serveRef } = refObj;
	const baseUrl = "https://gricd-africa.herokuapp.com/v2/enterprise";

	const [data, setData] = useState([]);
	const [appFeatures, setAppFeatures] = useState([]);
	const [currency, setCurrency] = useState("");

	useEffect(() => {
		axios.get(`${baseUrl}/pricings`).then((res) => {
			setData(res.data.pricings);
			setCurrency(res.data.pricings[0].monthlyCost.substring(0, 1));
		});
	}, []);

	useEffect(() => {
		window.localStorage.removeItem("state");
	}, []);

	useEffect(() => {
		if (data.length > 0) {
			let groups = {};
			data.map(({ planfeatures, name }) => {
				planfeatures.map(
					({ name: planName, active, group, description, value }) => {
						if (groups[group]) {
							if (groups[group][planName] >= 0) {
								groups[group].array[groups[group][planName]] = {
									...groups[group].array[
										groups[group][planName]
									],
									[name]: value ? value : active,
								};
							} else {
								groups[group].array = [
									...groups[group].array,
									{
										planName,
										description,
										[name]: value ? value : active,
									},
								];
								groups[group][planName] =
									groups[group].array.length - 1;
							}
						} else {
							groups[group] = {
								array: [
									{
										planName,
										description,
										[name]: value ? value : active,
									},
								],
							};
							groups[group][planName] =
								groups[group].array.length - 1;
						}
					}
				);
			});
			setAppFeatures(groups);
		}
	}, [data]);

	return (
		<div className="pd_home">
			<div className="fixed_div"></div>

			<Hero />
			<Serve serveRef={serveRef} />
			<SoftwareInfo executeScroll={executeScroll} />
			{/* <About aboutUsRef={aboutUsRef} />  */}
			<Choose executeScroll={executeScroll} />
			<FifthSection />
			<Application />
			<Plan
				pricings={data}
				appFeatures={appFeatures}
				currency={currency}
				plansRef={plansRef}
			/>
			{/* <Review /> */}
			<FAQ faqRef={faqRef} />
			<Client />
		</div>
	);
};

export default Home;

import Feature from "./feature";
import "./style.scss";
import { FormProvider, useForm } from "react-hook-form";
import ellipse from "../../assets/icons/ellipse.svg";
import SelectField from "../../components/Input/SelectField";
import lefticon from "../../assets/icons/lefticon.svg";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { formatCurrency } from "../../utils";
const AddOn = () => {
  const methods = useForm();
  const { state } = useLocation();
  const { _id, pricings, currency, period } = state || {};
  const navigate = useNavigate();
  const [plansObj, setPlanObj] = useState({});
  const [id, setId] = useState(_id);
  const [duration, setDuration] = useState(period);
  const [totalPeriod, setTotalPeriod] = useState(1);
  const [showDropdown, setShowDropdown] = useState(null);

  const dropdown = useRef(null);
  //closes the dropdown
  useEffect(() => {
    const hide = (e) => {
      if (!dropdown.current?.contains(e.target)) {
        setShowDropdown(null);
      }
    };

    window.addEventListener("mousedown", hide);
    return () => {
      window.removeEventListener("mousedown", hide);
    };
  }, []);

  useEffect(() => {
    if (!state) return navigate("/");
    const data = pricings.reduce((obj, plan) => {
      obj[plan._id] = plan;
      return obj;
    }, {});
    setPlanObj(data);
  }, []);

  useEffect(() => {
    if (id === "62a593352a9ec30680c69435") {
      setDuration("monthlyCost");
      methods.setValue("duration", "monthlyCost");
      setTotalPeriod(1);
    }
  }, [id]);

  console.log(duration);

  console.log(id);

  const onSubmit = (data) => {
    console.log(data);
    navigate("/checkout", {
      state: {
        addonList,
        id,
        totalPrice: totalPrice(),
        totalMonths: totalMonths(),
        currency,
        totalAddon: totalAddons(),
        plan: plansObj[id],
        duration,
      },
    });
  };
  const [addonList, setAddOnList] = useState([]);

  const totalAddons = () => {
    return addonList?.reduce((sum, addon) => sum + Number(addon.price), 0);
  };

  const addToList = (item) => {
    setAddOnList((prev) => {
      return [...prev, item];
    });
  };

  const handleCustomChange = (val) => {
    setId(val);
    setAddOnList([]);
  };

  const addAddons = ["62a5873b48b8ed1c2088c21b", "62a589088cf09c25d4c44de9"];

  const btnIsDisabled = (id) => {
    if (addAddons.includes(id)) return false;
    return addonList.some(({ _id }) => _id === id);
  };

  const totalMonths = () => {
    if (duration === "annualCost") return totalPeriod * 12;
    return totalPeriod;
  };

  const totalPrice = () => {
    const number = (string) => {
      return Number(string.substring(1));
    };
    return (number(plansObj[id][duration]) + totalAddons()) * totalPeriod;
  };

  function totalDevices() {
    return (
      Number(plansObj[id].numberofDevices) +
      addonList.filter(({ _id }) => _id === addAddons[0]).length
    );
  }

  function totalUsers() {
    return (
      Number(plansObj[id].assignedUser) +
      addonList.filter(({ _id }) => _id === addAddons[1]).length
    );
  }

  function rightClick() {
    if (id === "62a593352a9ec30680c69435") return;
    setTotalPeriod(totalPeriod + 1);
  }
  function leftClick() {
    if (id === "62a593352a9ec30680c69435") return;
    if (totalPeriod === 1) return;
    setTotalPeriod(totalPeriod - 1);
  }

  const removeAddon = (idx) => {
    let temp = [...addonList];
    temp.splice(idx, 1);
    setAddOnList(temp);
  };

  return (
    <div className="pd_add_on">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="cover_add section1">
            <p className="title">Add Ons</p>
            <div className="addon_flex">
              <div className="left">
                {plansObj[id]?.addons?.map((item) => {
                  return (
                    <Feature
                      currency={currency}
                      addToList={addToList}
                      key={item._id}
                      item={item}
                      disabled={btnIsDisabled(item._id)}
                    />
                  );
                })}
              </div>
              {Object.keys(plansObj).length > 0 && (
                <div className="right">
                  <div className="cover_form_side">
                    <div className="form-group-wrap">
                      <SelectField
                        label="Plan"
                        name="plan"
                        selectOption={pricings
                          .slice(0, 4)
                          .map(({ name, _id }) => ({
                            label: name,
                            value: _id,
                          }))}
                        val={_id}
                        handleCustomChange={handleCustomChange}
                      />

                      <SelectField
                        label="Duration"
                        name="duration"
                        selectOption={[
                          { label: "Annually", value: "annualCost" },
                          { label: "Monthly", value: "monthlyCost" },
                        ]}
                        val={duration}
                        handleCustomChange={(value) => setDuration(value)}
                        isDisabled={id === "62a593352a9ec30680c69435"}
                      />
                    </div>
                    <div className="details_box">
                      <div className="text_flex">
                        <p className="left_text">Plan: {plansObj[id]?.name}</p>
                        <p className="right_text">
                          {currency}
                          {formatCurrency(plansObj[id][duration].substring(1))}
                        </p>
                      </div>
                      <div className="text_flex">
                        <p className="left_text">
                          Duration:{" "}
                          {duration === "annualCost" ? "Annual" : "Monthly"}
                        </p>
                        <div className="right_box">
                          <img
                            src={lefticon}
                            className={`arr ${
                              id !== "62a593352a9ec30680c69435" && "cursor"
                            }`}
                            alt="left"
                            onClick={leftClick}
                          />
                          <div className="text_square">
                            <p>{totalPeriod}</p>
                          </div>
                          <img
                            src={lefticon}
                            alt="right"
                            className={`arr rotate ${
                              id !== "62a593352a9ec30680c69435" && "cursor"
                            }`}
                            onClick={rightClick}
                          />
                        </div>
                      </div>
                      <div className="text_flex">
                        <p className="left_text">Number of Device</p>
                        <p className="right_text">{totalDevices()}</p>
                      </div>
                      <div className="text_flex">
                        <p className="left_text">Number of User</p>
                        <p className="right_text">{totalUsers()}</p>
                      </div>
                      <div className="line"></div>
                      <p className="summary">Add-ons Summary</p>
                      <div ref={dropdown}>
                        {addonList.map(({ name, price }, i) => (
                          <div className="text_flex">
                            <p className="left_text dark">{name}</p>
                            <div className="right_box">
                              {" "}
                              <p>
                                {currency}
                                {price}
                              </p>{" "}
                              <span className="relative">
                                <img
                                  src={ellipse}
                                  alt="ellipse"
                                  className="ellipse"
                                  onClick={() => setShowDropdown(i)}
                                />{" "}
                                {showDropdown === i && (
                                  <p
                                    class="absolute delete-item"
                                    // v-if="addonId === i"
                                    onClick={() => removeAddon(i)}
                                  >
                                    Delete Item
                                  </p>
                                )}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="line total"></div>
                      <div className="text_flex">
                        <p className="left_text dark">Total Bill</p>
                        <p className="right_text">
                          {currency}
                          {formatCurrency(totalPrice())}
                        </p>
                      </div>

                      <button className="proceed">Proceed to checkout</button>
                    </div>
                  </div>
                </div>
              )}
            </div>{" "}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddOn;

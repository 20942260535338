import mark from "../../../../assets/icons/mark.svg";
import laptop from "../../../../assets/images/laptop.png";
import olu from "../../../../assets/icons/olu.svg";
import gallery from "../../../../assets/icons/gallery.svg";
import sub from "../../../../assets/icons/sub.svg";
import dispatch from "../../../../assets/icons/dispatch.svg";

import dashboard from "../../../../assets/images/dashboard.png";
import box from "../../../../assets/icons/box.svg";
import critical from "../../../../assets/icons/critical.svg";
import alarm from "../../../../assets/icons/alarm.svg";

import "./style.scss";
const Choose = ({ executeScroll }) => {
  return (
    <div className="pd_choose">
      <div className="flex_choose">
        <div className="text_choose">
          <p className="why">Why Choose Us</p>
          <p className="title">
            {/* Track your product <br /> in Real-Time with the <br /> best way
            possible */}
            Track your products <br /> and assets with the <br /> Enterprise
            Software
          </p>
          <div className="line"></div>
          <div className="cover_mark">
            <div className="flex_mark">
              <img src={mark} alt="mark" />
              <p>Temperature and Humidity Tracking</p>
            </div>
            <div className="flex_mark">
              <img src={mark} alt="mark" />
              <p>Location Tracking</p>
            </div>
            <div className="flex_mark">
              <img src={mark} alt="mark" />
              <p>Remote/Real-Time Asset Monitoring</p>
            </div>
            <div className="flex_mark">
              <img src={mark} alt="mark" />
              <p>Generate Unlimited Quality Assurance Reports</p>
            </div>
            <div className="flex_mark">
              <img src={mark} alt="mark" />
              <p>24/7 Support</p>
            </div>
          </div>
          <button
            onClick={() => executeScroll("plansRef")}
            className="btn"
            style={{ position: "relative", top: "40px" }}
          >
            Get Started
          </button>
        </div>
        {/* <div className="image_choose">
          <img src={olu} alt="olu" className="olu" />
          <img src={gallery} alt="gallery" className="gallery" />
          <img src={sub} alt="sub" className="sub" />
          <img src={dispatch} alt="dispatch" className="dispatch" />
          <img src={laptop} alt="laptop" />
        </div> */}

        <div className="image_about_side">
          <div className="base">
            <img src={box} alt="box" />
          </div>
          <img src={critical} alt="critical" className="critical" />

          <div className="alarm">
            <div className="cover_red">
              <p>3</p>
              <img src={alarm} alt="alarm" />
            </div>
          </div>
          <img src={dashboard} alt="dashboard" className="dashboard" />
        </div>
      </div>
    </div>
  );
};

export default Choose;

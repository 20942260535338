import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import indicator from "../../../../assets/icons/indicate.svg";
import greenmark from "../../../../assets/icons/greenmark.svg";
import redmark from "../../../../assets/icons/redmark.svg";
import mail from "../../../../assets/icons/mail.png";
import phone from "../../../../assets/icons/call.png";
import { useNavigate } from "react-router";
import { Skeleton, Tooltip } from "@mui/material";

const Plan = ({ pricings, appFeatures, currency, plansRef }) => {
  const [toggle, setToggle] = useState(false);

  const dropdown = useRef(null);
  //closes the dropdown

  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (modal) {
      var x = window.scrollX;
      var y = window.scrollY;
      window.onscroll = function () {
        window.scrollTo(x, y);
      };
    } else {
      window.onscroll = null;
    }
  }, [modal]);

  const sendMessage = (e) => {
    e.preventDefault();
    setModal(!modal);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setModal(false);
  };

  const navigate = useNavigate();
  const handleToggle = () => {
    setToggle(!toggle);
  };

  const renderFeature = (key) => {
    if (typeof key === "string") {
      return <p style={{ textAlign: "center" }}>{key}</p>;
    }
    if (key) {
      return <img src={greenmark} alt="greenmark" />;
    }
    return <img src={redmark} alt="greenmark" />;
  };

  const handleBtnClick = (data) => {
    const { _id, name } = data;
    if (name === "Enterprise") {
      return setModal(!modal);
    }
    navigate("/addon", {
      state: {
        _id,
        pricings,
        currency,
        period: toggle ? "monthlyCost" : "annualCost",
      },
    });
  };

  console.log("pricings", pricings);

  return (
    <div className="pd_plan" ref={plansRef}>
      {modal && <div className="overlay" onClick={closeModal} />}
      <p className="title">Choose the plan that works for you</p>
      <div className="toggle_flex">
        <p>Billed Annually</p>
        <div className="togDiv">
          <label className="switch">
            <input checked={toggle} onClick={handleToggle} type="checkbox" />
            <span className="slider round"></span>
          </label>
        </div>
        <p>Billed Monthly</p>
      </div>
      <div className="table_cover">
        <div className="overflow_table">
          <table>
            <thead>
              <tr>
                <th className="big"></th>
                <th>Freemium</th>
                <th>Basic</th>
                <th>Pro</th>
                <th>Premium</th>
                <th>Enterprise</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="big">
                  <div className="flex">
                    <p>Annual Cost</p>
                    <img src={indicator} alt="indicator" />
                  </div>
                </td>
                {pricings
                  ? pricings.map(({ annualCost, name }) => (
                      <td className="amount">{name.toLowerCase() === "freemium" ? "-" : annualCost}</td>
                    ))
                  : Array(5)
                      .fill(5)
                      .map((_) => (
                        <td className="amount">
                          <Skeleton variant="rectangular" height={20} />
                        </td>
                      ))}
              </tr>
              <tr>
                <td className="big">
                  <div className="flex">
                    <p>Monthly Fee</p>
                    <img src={indicator} alt="indicator" />
                  </div>
                </td>
                {pricings
                  ? pricings.map(({ monthlyCost, name }) => (
                      <td className="amount">
                        {name.toLowerCase() === "freemium"
                          ? "1 month"
                          : monthlyCost}
                      </td>
                    ))
                  : Array(5)
                      .fill(5)
                      .map((_) => (
                        <td className="amount">
                          <Skeleton variant="rectangular" height={20} />
                        </td>
                      ))}
              </tr>
              <tr>
                <td className="green big">Subscription Overview</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td className="big">
                  <div className="flex">
                    <p>Annual Calibration Certificate</p>
                    <img src={indicator} alt="indicator" />
                  </div>
                </td>
                <td className="amount">-</td>
                <td className="amount">-</td>
                <td className="amount">-</td>
                <td className="amount">-</td>
                <td className="amount">-</td>
              </tr>
              <tr>
                <td className="big">
                  <div className="flex">
                    <p>Data Storage Range</p>
                    <img src={indicator} alt="indicator" />
                  </div>
                </td>
                {pricings
                  ? pricings.map(({ dataStorageRange }) => (
                      <td className="amount">{dataStorageRange}</td>
                    ))
                  : Array(5)
                      .fill(5)
                      .map((_) => (
                        <td className="amount">
                          <Skeleton variant="rectangular" height={20} />
                        </td>
                      ))}
              </tr>
              {Object.keys(appFeatures).length > 1 ? (
                Object.entries(appFeatures).map(([key, { array }]) => (
                  <React.Fragment>
                    <tr>
                      <td className="green big">{key}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {array.map(
                      ({
                        planName,
                        Enterprise,
                        Basic,
                        Freemium,
                        Pro,
                        Advanced,
                        description,
                      }) => (
                        <tr>
                          <td className="big">
                            <div className="flex">
                              <p>{planName}</p>
                              <Tooltip title={description} placement="top">
                                <img
                                  className="cursor"
                                  src={indicator}
                                  alt="indicator"
                                />
                              </Tooltip>
                            </div>
                          </td>
                          <td>{renderFeature(Freemium)}</td>
                          <td>{renderFeature(Basic)}</td>
                          <td>{renderFeature(Pro)}</td>
                          <td>{renderFeature(Advanced)}</td>
                          <td>{renderFeature(Enterprise)}</td>
                        </tr>
                      )
                    )}
                  </React.Fragment>
                ))
              ) : (
                <Skeleton variant="rectangular" height={20} />
              )}
            </tbody>
          </table>
        </div>

        <div className="overflow_table btn-view cover_btn">
          <table>
            <tbody>
              <tr>
                <td className="big">
                  <div className="flex"></div>
                </td>
                {pricings
                  ? pricings.map((data) => (
                      <td className="amount">
                        <div className="relative">
                          <button
                            onClick={() => handleBtnClick(data)}
                            disabled={!pricings}
                          >
                            Choose Plan
                          </button>

                          {data.name === "Enterprise" && modal && (
                            <ul className="modal">
                              <li>
                                <a
                                  href="mailto:sales@gricd.com"
                                  className="link"
                                  onClick={() => setModal(false)}
                                >
                                  <img src={mail} alt="mail" />
                                  Email
                                </a>
                              </li>
                              <li>
                                <a
                                  href="tel:+234 814 298 1261"
                                  className="link"
                                  onClick={() => setModal(false)}
                                >
                                  <img src={phone} alt="phone" />
                                  Call
                                </a>
                              </li>
                            </ul>
                          )}
                        </div>
                      </td>
                    ))
                  : Array(5)
                      .fill(5)
                      .map((_) => (
                        <td className="amount">
                          <Skeleton variant="rectangular" height={20} />
                        </td>
                      ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Plan;

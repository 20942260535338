import client1 from "../../../../assets/icons/client1.svg";
import client2 from "../../../../assets/icons/client2.svg";
import client3 from "../../../../assets/icons/client3.svg";
import client4 from "../../../../assets/icons/client4.svg";
import client5 from "../../../../assets/icons/client5.svg";
import client6 from "../../../../assets/icons/client6.svg";
import client7 from "../../../../assets/icons/client7.svg";
import "./style.scss";
const Client = () => {
  return (
    <div className="pd_client">
      <p className="title">Our Clients</p>
      <div className="flex_client">
        <img src={client1} alt="client" />
        <img src={client2} alt="client" />
        <img src={client3} alt="client" />
        <img src={client4} alt="client" />
        <img src={client5} alt="client" />
        <img src={client6} alt="client" />
        <img src={client7} alt="client" />
      </div>
    </div>
  );
};

export default Client;

import "./style.scss";
import three from "../../../../assets/images/three.png";
const FifthSection = () => {
  return (
    <div className="pd_fifth_section">
      <div className="top_side">
        <div className="cover_text">
          <p className="cold">
            Build a Risk-Free Supply Chain <br /> Monitoring System with the
            Enterprise Software
          </p>
        </div>
      </div>
      <div className="bottom_side">
        <img src={three} alt="three" />
      </div>
    </div>
  );
};

export default FifthSection;

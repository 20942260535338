import { useRef } from "react";
import "./style.scss";
const EachCarousel = ({ item, handleClick }) => {
  const email = useRef(null);
  return (
    <div
      style={{ backgroundImage: `url(${item.image})` }}
      className="each_carousel_box"
    >
      <div className="text_box">
        <p className="real"> REAL TIME</p>
        <p className="main_text">
          Cold-Chain <br /> Monitoring <br /> For <span>{item.text}</span>
        </p>
        <p className="sub_text">
          Vaccines or blood vials, frozen foods or fresh Agro produce, our
          cold-chain solutions will ensure the safety of your
          temperature-sensitive products.
        </p>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleClick(email);
          }}
        >
          <input
            type="email"
            placeholder="Enter your email"
            ref={email}
            style={{ cursor: "text" }}
            required
          />
          <button type="submit">Schedule an appointment</button>
        </form>
      </div>
    </div>
  );
};

export default EachCarousel;

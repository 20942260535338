import monitor from "../../../../assets/icons/monitor.svg";
import scale from "../../../../assets/icons/scale.svg";
import eliminate from "../../../../assets/icons/eliminate.svg";
import "./style.scss";
const Serve = ({ serveRef }) => {
  return (
    <div className="pd_serve" ref={serveRef}>
      <div className="cover_serve">
        {/* <p className="title_serve">Who do we serve</p>
        <p className="solution">Our cold-chain solutions are used by</p>
        <p className="sub_text">
          Vaccines or blood vials, frozen foods or fresh Agro produce, our
          cold-chain solutions <br /> will ensure the safety of your
          temperature-sensitive products.
        </p> */}
        <p>Do More with Real-Time </p> <br />{" "}
        <p>Supply Chain and Cold-Chain Visibility</p>
        <div className="cov_flex">
          <div className="flex_side">
            {/* {flexList.map((item) => {
            return (
              <div className="each_flex">
                <img src={item.image} alt="flex" />
                <p className="title">{item.title}</p>
                <p className="text">{item.text}</p>
              </div>
            );
          })} */}
            <div className="each_flex">
              <img src={monitor} alt="flex" />
              <p className="title">
                Monitor your <br /> Products
              </p>
              <p className="text">
                Get real time data on the location, <br /> temperature and
                humidity of your <br /> products in storage and in transit
              </p>
            </div>
            <div className="each_flex">
              <img src={scale} alt="flex" />
              <p className="title">
                Scale your <br /> Operation
              </p>
              <p className="text">
                {" "}
                Plan your operations, and make <br /> informed and timely
                decisions with <br /> real-time data and alarm triggers
              </p>
            </div>
            <div className="each_flex">
              <img src={eliminate} alt="flex" />
              <p className="title">
                Avoid Product <br />
                Waste
              </p>
              <p className="text">
                Get alerted in real-time whenever your <br /> products are out
                of the specified <br /> storage conditions.
              </p>
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default Serve;

import shield from "../../assets/icons/shield.svg";
import down from "../../assets/icons/down.svg";
import "./style.scss";
const Feature = ({ item, addToList, currency, disabled }) => {
  return (
    <div className="pd_feature">
      <div className="top_side">
        <div className="left_part">
          <img src={shield} alt="shield" />
          <p>{item.name}</p>
        </div>
        <p className="amount">
          {currency}
          {item.price}
        </p>
      </div>
      <p className="mid_text">{item.description}</p>
      <div className="last_side">
        <div className="left_part">
          {/* <p>Show More</p>
          <img src={down} alt="down" /> */}
        </div>
        <button
          onClick={() => {
            addToList(item);
          }}
          type="button"
          className="add"
          disabled={disabled}
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default Feature;

import footerlogo from "../../../../assets/icons/footerlogo.svg";
import messanger from "../../../../assets/icons/messanger.svg";
import twitter from "../../../../assets/icons/twitter.svg";
import linkedin from "../../../../assets/icons/in.svg";
import { Link } from "react-router-dom";
import "./style.scss";
const Footer = ({ executeScroll }) => {
  const gpenNewTab = (url) => {
    window.open(url, "_blank");
  };
  return (
    <footer className="pd_footer">
      <div className="footer_flex">
        <div className="each logoeach">
          <Link to="/">
            <img src={footerlogo} alt="logo" className="logo" />
          </Link>
          <p className="text">
            Preserving Cold Chain and <br /> Supply Chain Integrity.
          </p>
          <div className="cover_img">
            <a
              href="https://wa.me/message/C7YXE3ZLXM7PE1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={messanger} alt="messanger" />
            </a>

            <a
              href="https://twitter.com/TheGricd"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitter} alt="twitter" />
            </a>

            <a
              href="https://www.linkedin.com/company/gricd/mycompany/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedin} alt="linkedin" />
            </a>
          </div>
        </div>
        <div className="each">
          <p className="section_title">Company</p>
          <div className="links">
            <div onClick={() => executeScroll("aboutUsRef")}>About Us</div>
            <div onClick={() => executeScroll("plansRef")}>Pricings</div>
            <div onClick={() => executeScroll("faqRef")}>FAQ</div>
          </div>
        </div>
        <div className="each">
          <p className="section_title">Get in Touch</p>
          <div className="links">
            <div onClick={() => executeScroll("faqRef")}>Contact Us</div>
            {/* <Link to={"/"}>Contact Us</Link> */}
            {/* <Link to={"/"}>Support</Link> */}
            <a href="http://support.gricd.com" target="_blank" rel="noreferrer">
              Support
            </a>
          </div>
        </div>
        <div className="each">
          <p className="section_title">Join Our Newsletter</p>
          <form action="">
            <input type="text" placeholder="Your Email Address" />
            <button>Subscribe</button>
          </form>
          <p className="update">
            * Will send you weekly updates for your better <br /> cold-chain
            management.
          </p>
        </div>
      </div>
      <div className="line"></div>
      <p className="copy">
        Copyright @Gricd {new Date().getFullYear()}. All Rights Reserved.
      </p>
    </footer>
  );
};

export default Footer;

import React from "react";
import ComputerImg from "../../../../assets/images/Gricd-Prototype 1.png";
import LocationImg from "../../../../assets/images/location 1.png";
import SearchImg from "../../../../assets/images/Frame.png";
import "./style.scss";

const SoftwareInfo = ({ executeScroll }) => {
  const details = [
    {
      logo: ComputerImg,
      title: "Enjoy Remote Monitoring",
      content:
        "You don’t have to be onsite to ensure your product's safety. Monitor the storage condition of your temperature-sensitive products from anywhere in the world with a laptop or desktop computer",
    },
    {
      logo: LocationImg,
      title: "Temperature, Humidity and Location Tracking",
      content:
        "Avoid waste, theft and avoidable eventualities. Track your product's location and storage condition onsite and in transit",
      reverse: true,
    },
    {
      logo: SearchImg,
      title: "Instant Quality Assurance Report",
      content:
        "Validate the Integrity of your products. Generate quality assurance reports on your product storage over any set period of time in minutes",
    },
  ];
  return (
    <div className="software-info">
      {details.map(({ content, title, logo, reverse }, index) => (
        <div
          className="container"
          key={index}
          style={reverse ? { flexFlow: "row-reverse" } : {}}
        >
          <div className="left">
            <p className="title"> {title}</p>
            <p className="content">{content}</p>
            <button onClick={() => executeScroll("plansRef")} className="btn">
              Get Started
            </button>
          </div>
          <div className="right">
            <img src={logo} alt="" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default SoftwareInfo;

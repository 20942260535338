/* eslint-disable react-hooks/exhaustive-deps */
import { FormProvider, useForm } from "react-hook-form";
import InputField from "../../components/Input";
import StepMovement from "../../components/StepMovement";
import Checkbox from "@mui/material/Checkbox";
import "./style.scss";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Icon from "../../assets/icons/Icon.svg";
import logo from "../../assets/icons/logo.svg";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Terms from "../../components/Terms";

const Checkout = () => {
  const methods = useForm();
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state) return navigate("/");
    if (state && state.data) {
      Object.entries(state.data).forEach(([key, value]) => {
        methods.setValue(key, value);
      });
      setCountry(state.data.country);
      setRegion(state.data.state);
      setChecked2(true);
      setChecked(state.data.checked);
    }
  }, []);

  const handleChange = (e) => {
    setChecked(e.target.checked);
  };
  const handleChange2 = (e) => {
    setChecked2(e.target.checked);
  };
  const onSubmit = (data) => {
    console.log(data, "data");
    data = { ...data, country, state: region, checked };
    let payload = { state, data };
    if (state.state) {
      payload = { state: state.state, data };
    }
    navigate("/summary", {
      state: payload,
    });
  };

  const accept = () => {
    setOpenModal(false);
    return setChecked2(true);
  };

  return (
    <div className="pd_checkout">
      <div className="cover_checkout">
        <StepMovement step={2} />
        <div className="bill_box">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <p className="title">Billing Adress</p>
              <div className="form-group-wrap">
                <InputField
                  type="name"
                  name="first_name"
                  placeholder="Name"
                  label="First Name"
                  style={{
                    cursor: "pointer",
                  }}
                  autoComplete="off"
                />
                <InputField
                  type="name"
                  name="last_name"
                  placeholder="Name"
                  label="Last Name"
                  style={{
                    cursor: "pointer",
                  }}
                  autoComplete="off"
                />
              </div>
              <InputField
                type="email"
                name="email"
                placeholder="Email"
                label="Email"
                style={{
                  cursor: "pointer",
                }}
                autoComplete="off"
              />
              <div className="additional">
                <InputField
                  type="password"
                  name="password"
                  placeholder="Password"
                  label="Password"
                  style={{
                    cursor: "pointer",
                  }}
                  autoComplete="off"
                  // validatePassword={true}
                />
                <p className="additional_text">
                  Strong passwords should be atleast 8 characters long with a mix of
                  upper case, lower case, number and special character
                </p>
              </div>
              <InputField
                type="text"
                name="company"
                placeholder="Company"
                label="Company"
                style={{
                  cursor: "pointer",
                }}
                autoComplete="off"
              />
              <div className="form-group-wrap">
                <CountryDropdown
                  value={country}
                  name="country"
                  onChange={(val) => setCountry(val)}
                  style={{
                    width: "120%",
                    border: "1px solid #b7c3cd",
                    borderRadius: "4px",
                    padding: "15px",
                    fontSize: "12px",
                    color: "#404a57",
                    marginBottom: "8px",
                    marginRight: "16px",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                />

                <RegionDropdown
                  country={country}
                  value={region}
                  name="region"
                  onChange={(val) => setRegion(val)}
                  style={{
                    width: "100%",
                    border: "1px solid #b7c3cd",
                    borderRadius: "4px",
                    padding: "15px",
                    fontSize: "12px",
                    color: "#404a57",
                    marginBottom: "8px",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                />
              </div>
              <div className="form-group-wrap">
                <InputField
                  type="text"
                  name="postal_code"
                  placeholder="Pointer Code"
                  label="Postal Code"
                  style={{
                    cursor: "pointer",
                    // width: "53%",
                  }}
                  autoComplete="off"
                  className="postal_code"
                />

                <InputField
                  type="tel"
                  name="phone"
                  placeholder="08038232323"
                  label="Phone number"
                  className="phone_class"
                />
              </div>

              <div className="check_flex">
                <Checkbox
                  checked={checked}
                  name="checked"
                  onChange={handleChange}
                />
                <p>My billing and shipping adress are the same</p>
              </div>
              <div className="check_flex">
                <Checkbox
                  checked={checked2}
                  name="checked2"
                  onChange={handleChange2}
                  required
                  disabled={!country || !region}
                />
                <p className="term">
                  I agree to the{" "}
                  <span onClick={() => setOpenModal(true)}>
                    Terms & Conditions
                  </span>
                </p>
              </div>

              <div className="submit_box">
                <img src={logo} alt="logo" className="logo" />
                <button className="submit btn">
                  Next <img src={Icon} alt="submit" />
                </button>
              </div>
            </form>
          </FormProvider>

          {openModal && (
            <Terms
              open={openModal}
              handleClose={() => setOpenModal(false)}
              accept={accept}
            />
          )}
        </div>{" "}
      </div>
    </div>
  );
};

export default Checkout;

import { Box, Modal } from "@mui/material";
import "./styles.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  borderRadius: "30px",
  //   height: "70vh",
};

const Terms = ({ open, handleClose, accept }) => {
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <p className="terms header-text ">Terms & Conditions</p>
          <section>
            <p className="font-18 mb-3 font-warranty">
              WARRANTY POLICY FOR THE GRICD PRODUCTS
            </p>
            <ol>
              <li className="mb-1">
                <span className="">General Remarks:</span>
                <ol>
                  <li>
                    Gricd Agro Allied Integrated Services Limited (Gricd) grants
                    a voluntary manufacturers’ warranty for the products
                    supplied and further itemized in clause 2.1 of this Policy
                    (hereinafter referred to as the “Covered Product(s)”) and
                    for the period specified in clause 4 of this Policy.
                  </li>
                  <li>
                    The content and scope of the warranty issued by Gricd shall
                    be governed exclusively by this Policy. To this end, in the
                    event of a conflict between this Policy and any other
                    agreement entered into between Gricd and the Warranty Holder
                    in relation to the Covered Product, the provisions of this
                    Policy shall prevail.
                  </li>
                  <li>
                    Gricd shall be entitled to amend this Policy at any time
                    with effect for the future. Notwithstanding the foregoing,
                    the Warranty available to each Warranty Holder shall be
                    those applicable at the time of the purchase of the Covered
                    Product.
                  </li>
                  <li className="mb-3">
                    This Warranty is in addition to and does not limit any legal
                    or contractual claims or rights of the Warranty Holder in
                    respect of the Covered Product.
                  </li>
                </ol>
              </li>

              <li className="mb-1">
                <span>Scope:</span>
                <ol>
                  <li className="mb-3">
                    This Warranty shall only apply to products purchased
                    directly from Gricd and an authorized reseller and/or a
                    representative of Gricd.
                  </li>
                  <li className="mb-3">
                    This Warranty shall be limited to the following products:
                    <ol>
                      <li>
                        The Gricd Cold Box including all functional parts
                        (including but not limited to the battery, electrical
                        and sealed system parts) and the Compressor of the Gricd
                        Cold Box. The Gricd Cold Box is to be utilized for the
                        storage and transportation of thermo-sensitive goods.
                      </li>
                      <li>
                        The Gricd Mote including all functional parts (battery,
                        internal probe) but not including the device charger. No
                        warranty on charger if missing or damaged due to
                        electrical malfunction.
                      </li>
                      <li>
                        The Gricd LoRa Mote and LoRa Gateway including other
                        malfunctioning parts: antenna, gateway charger, within
                        the warranty period. No warranty on charger and antenna
                        if missing, broken, or damaged due to electrical
                        malfunction.
                      </li>
                    </ol>
                  </li>

                  <li className="mb-3">
                    The following shall be excluded from this Warranty:
                    <ol>
                      <li>
                        All products provided by Gricd to customers as
                        prototypes for test purposes;
                      </li>
                      <li>
                        All parts or components of the Covered Product not
                        originally sold or marketed by Gricd;
                      </li>
                      <li>
                        All components of the Covered Product which are subject
                        to regular wear and tear.
                      </li>
                    </ol>
                  </li>

                  <li className=" mb-1">
                    <span>The Warrantor:</span>
                    <p className="mb-3">
                      The Warrantor under this Policy shall be Gricd, a Company
                      duly incorporated under the laws of the Federal Republic
                      of Nigeria.
                    </p>
                  </li>
                  <li className="mb-3">
                    <span>The Warranty Holder:</span>
                    <ol>
                      <li className="mb-3">
                        The Warranty Holder shall be any person(s) who has
                        acquired the Covered Product from Gricd pursuant to
                        clause 2.1 and is operating the product for the first
                        time in accordance with its intended use (the “First
                        Purchaser”).
                      </li>
                      <li className="mb-3">
                        All distributors or resellers who do not operate the
                        product for their own purposes are not entitled to the
                        Warranty provided hereunder.
                      </li>

                      <li className="mb-3">
                        This Warranty may be transferred by the First Purchaser
                        to another person together with the Covered Product and
                        maintained. Provided that:
                        <ol>
                          <li>
                            The Covered Product has not been moved from the
                            place of First Use;
                          </li>
                          <li>
                            No modifications have been made to the Covered
                            Product;
                          </li>
                          <li>
                            No new warranty agreement has been entered into with
                            the new party, who merely takes over the Warranty of
                            the First Purchaser to the extent that it existed in
                            relation to the First Purchaser at the time of the
                            transfer;
                          </li>
                          <li>
                            A written notice of the change in ownership of the
                            Covered Product is given to Gricd, at least 10 (Ten)
                            days prior to the effective date of the change of
                            ownership.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li className="detail-list">
                <span className=" mb-1">Warrant Claim:</span>
                <ol>
                  <li className="mb-3">
                    A Warranty Claim shall exist if:
                    <ol>
                      <li>
                        The Covered Product has a material or manufacturing
                        defect for which Gricd is responsible;
                      </li>
                      <li>
                        Such a defect affects the operation of the Covered
                        Product;
                      </li>
                      <li>
                        The defect occurs during the normal use of the Covered
                        Product;
                      </li>
                      <li>
                        And the Warranty Holder’s claim is not excluded as
                        contained in Clause 6 of this Policy
                      </li>
                    </ol>
                  </li>
                  <li>
                    All defects which do not affect the proper functioning of
                    the Covered Product (visual defects, blemishes etc.) shall
                    not be covered by this Warranty.
                  </li>
                </ol>
              </li>
              <li className="detail-list">
                <span className=" mb-1">Warranty Period:</span>
                <ol>
                  <li className="mb-3">
                    The Warranty Period shall begin on the date of delivery of
                    the Covered Product to the Warranty Holder or the date on
                    which the Covered Product was invoiced by Gricd, whichever
                    is later and shall continue for a period of 12 (twelve)
                    months.
                  </li>
                  <li className="mb-3">
                    The Warranty Period shall commence in accordance with clause
                    4.1 irrespective of when the Covered Product was put to use
                    or was not performing as a result of being broken down or
                    being under repair.
                  </li>
                  <li className="mb-3">
                    If the Covered Product or any part or component of the
                    Covered Product is replaced or repaired, the Warranty Period
                    for the replaced or repaired product or component shall not
                    start running afresh and no new warranty certificate will be
                    issued in respect of same. To this end, the Warranty shall
                    end on the date upon which the Warranty for the original
                    Covered Product ends.
                  </li>
                </ol>
              </li>
              <li className="detail-list">
                <span className=" mb-1">Warranty Exclusions:</span>
                <p className="mb-3">
                  Warranty Claims shall be excluded in the event that the defect
                  was occasioned by the following
                </p>
                <ol>
                  <li>
                    Failure of the Warranty Holder to comply with the
                    installation or operation instructions as provided by Gricd,
                    during the installation, commissioning, operation of the
                    Covered Product;
                  </li>
                  <li>
                    Improper transportation, packaging or storage of the Covered
                    Product;
                  </li>
                  <li>
                    Lack of proper maintenance of the Covered Product in
                    accordance with the operating instructions;
                  </li>
                  <li>
                    Use of the Covered Product in a manner not corresponding to
                    the normal use of same and non-compliance with any
                    applicable safety regulations for the use of the Covered
                    Product;
                  </li>
                  <li>
                    Unauthorized intervention or intervention by third parties
                    not authorized by Gricd in the Covered Product, in the form
                    of openings, modifications, repairs, conversions and use of
                    accessories not supplied or authorized by Gricd;
                  </li>
                  <li>
                    Events that are due to circumstances for which Gricd is not
                    responsible or that are not attributable to normal operating
                    conditions, such as power fluctuations, overvoltage,
                    lightning, fire, flooding, or other liquid intrusion,
                    tampering or damage caused by the Warranty Holder, third
                    parties or the impact of foreign objects;
                  </li>
                  <li>
                    Any non-functional or abnormal function with any external
                    device connected with the Covered Product, including but not
                    limited to e.g Solar Panels;
                  </li>
                  <li>Force Majeure</li>
                </ol>
              </li>
              <li>
                <span className=" mb-1">Warranty Services:</span>
                <ol>
                  <li className="mb-3">
                    In the event of a Warranty Claim, the following remedies
                    shall be available to the Warranty Holder in respect of the
                    Covered Product:
                    <ol>
                      <li>
                        The defective unit is repaired on the Warranty Holder’s
                        site, or in a repair center operated or appointed by
                        Gricd;
                      </li>
                      <li>
                        The defective unit is exchanged for an equivalent unit
                        of the same specification and condition; or
                      </li>
                      <li>
                        A credit note is issued for the same value as the
                        purchase price paid by the Warranty Holder for the
                        Covered Product, and same shall be used to purchase a
                        new product from Gricd.
                      </li>
                    </ol>
                  </li>
                  <p className="mb-3">
                    Clause 6.1.1-6.1.3 shall hereinafter be individually or
                    jointly referred to as the “Warranty Service(s)”
                  </p>

                  <li className="mb-3">
                    Notwithstanding anything contained in this Policy or
                    otherwise stated, the remedy available to the Warranty
                    Holder shall be determined solely by Gricd following the
                    completion of a fault diagnosis on the Covered Product.
                    Nothing in this Policy shall be interpreted to confer an
                    automatic right to a replacement of a Covered Product at the
                    demand of the Warranty Holder and without the completion of
                    the fault diagnosis.
                  </li>
                  <li className="mb-3">
                    In the case of a repair on the Warranty Holder’s site:
                    <ol>
                      <li>
                        If Gricd decides that the defective unit should be
                        repaired on the Warranty Holder’s site, the repair must
                        be carried out by Gricd or a professional duly
                        authorized by Gricd;
                      </li>
                      <li className="mb-3">
                        The Warranty Holder shall provide unobstructed access
                        and procure all necessary permits as may be required to
                        gain access to the Covered Products, provide any
                        apparatus required by applicable occupational safety
                        regulations (free of charge) and as may be required to
                        enable Gricd render the Warranty Services.
                      </li>
                    </ol>
                  </li>

                  <li className="detail-list">
                    In case of a repair in a Repair Center operated or appointed
                    by Gricd:
                    <ol>
                      <li>
                        The Warranty Holder shall ensure proper dismantling and
                        transportation of the Covered Products to a repair
                        center as specified by Gricd. Following the repair,
                        Gricd shall be responsible for returning the repaired
                        unit to the Warranty Holder.
                      </li>
                      <li>
                        If Gricd decides that the defective unit is to be
                        replaced, the Warranty Holder undertakes to ensure
                        proper dismantling and transportation of the defective
                        unit to the address specified by Gricd.
                      </li>
                      <li>
                        Gricd may, at its discretion, arrange for the
                        replacement unit to be sent before the defective unit is
                        in its custody. In this case, Gricd shall be entitled to
                        demand financial security in the amount of the value of
                        the replacement unit, including transportation costs and
                        Gricd shall retain ownership of the supplied replacement
                        unit until the defective unit has been received.
                      </li>
                      <li>
                        All defective units to be replaced shall, upon receipt
                        by Gricd, become the property of Gricd.
                      </li>
                    </ol>
                  </li>
                  <li className="mt-2 mb-3">
                    All labour and transportation costs required for the
                    provision of the Warranty Services shall be borne by Gricd
                    during the Warranty Period. However, all other incidental
                    costs in relation thereof including but not limited to
                    shipping of the Covered Product/ defective unit to Gricd
                    shall be borne by the Warranty Holder.
                  </li>
                  <li className="mb-3">
                    Further to the preceding clause, the Warranty Holder shall
                    be responsible for the cost and repair of any defect in the
                    electrical installation or wiring at the site of the Covered
                    Product Usage, where applicable.
                  </li>
                  <li className="mb-3">
                    Gricd shall make all reasonable efforts to carry out the
                    Warranty Services within a period of 5 (five) business days
                    from the date the Covered Product is brought into Gricd’s
                    repair center or the date of Gricd’s arrival for repairs on
                    the Warranty Holder’s site (in the case of an onsite
                    repair).
                  </li>
                  <li className="mb-3">
                    Notwithstanding the foregoing, the Warranty Holder
                    acknowledges that delays may occur as a result of forces
                    beyond the reasonable control of Gricd. In such instances,
                    Gricd shall not be held liable or deemed to be in default of
                    its obligations under this Warranty. Such instances shall
                    include but not be limited to the following:
                    <ol>
                      <li>
                        Delays due to the unavailability of and inability to
                        import any part, component, accessory or supplies
                        required for rendering the Warranty Services;
                      </li>
                      <li>
                        Delays due to the unavailability of a qualified
                        professional to render the required Warranty Services;
                      </li>
                      <li>
                        Delays occasioned as a result of restrictions and
                        regulations of the government or any governmental body
                        and public movement.
                      </li>
                      <li>
                        Delays occasioned by reason of an Act of God, war,
                        insurrection, external elements, strikes, labor
                        disputes, or other similar or dissimilar causes beyond
                        the reasonable control of Gricd.
                      </li>
                    </ol>
                  </li>
                  <li className="mb-3">
                    In the event of such delays as, Gricd shall notify the
                    Warranty Holder of its inability to render the Warranty
                    Services within the stipulated timeframe within 3 (three)
                    business days from the discovery of the delay.
                  </li>
                </ol>
              </li>
              <li className=" mb-1">
                <span>Warranty Extensions:</span>
                <p className="mb-3">
                  The Warranty provided under this Policy shall not be extended
                  following the expiration of the Warranty Period.
                </p>
              </li>
              <li className="detail-list">
                <span className=" mb-1">Claiming Services Under Warranty:</span>
                <ol>
                  <li className="mb-3">
                    The Warranty Holder must notify Gricd of any Warranty Claim
                    for the Covered Product within the Warranty Period.
                  </li>
                  <li className="mb-3">
                    In order for Gricd to process the Warranty Claim, the
                    Warranty Holder shall be required to provide the following:
                    <ol>
                      <li>
                        A copy of the Agreement/ document which confers this
                        Warranty on the Warranty Holder;
                      </li>
                      <li>Sales Invoice in respect of the Covered Product;</li>
                      <li>
                        A letter/email containing a full description of the
                        issue with the Covered Product.
                      </li>
                    </ol>
                  </li>
                  <li>
                    In addition, while the claim is being processed, the
                    Warranty Holder or their authorized representatives must
                    also provide Gricd with all further information in order to
                    be able to carry out a proper fault diagnosis.
                  </li>

                  <li className="mb-3">
                    Where the Warranty Holder or its authorized representatives
                    (where applicable) fail to meet the requirements of clause
                    8.1 - 8.3 as stated above, Gricd shall not be obliged to
                    provide services under this Warranty.
                  </li>
                </ol>
              </li>
              <li className=" mb-1">
                <span>
                  {" "}
                  Applicable Law, Jurisdiction and Dispute Resolution:
                </span>
                <ol>
                  <li className="mb-3">
                    All claims and disputes arising from or in connection with
                    this Warranty shall be subject to Nigerian Law.
                  </li>
                  <li className="mb-3">
                    If any dispute or difference arises as to the validity,
                    interpretation, effect or rights and obligations of the
                    Parties under this Policy, the Parties shall use their best
                    endeavour to reach an amicable settlement of the dispute
                    through deliberations between the authorised representatives
                    of Gricd and the Warranty Holder. If any dispute is not
                    settled by mediation within thirty (30) days, the Parties
                    agree to submit such dispute to the exclusive jurisdiction
                    of the Nigerian courts.
                  </li>
                  <li>
                    The place of performance for obligations arising under this
                    Warranty shall be Lagos, Nigeria.
                  </li>
                </ol>
              </li>
            </ol>
          </section>
          <div className="term-btn">
            <button
              type="button"
              onClick={accept}
              className="btn btn-primary mt-4 mx-auto d-block px-5"
            >
              Accept
            </button>
            <button
              type="button"
              className="btn btn-secondary mt-4 mx-auto d-block px-5"
              onClick={handleClose}
              //   @click="$emit('logoutUser')"
            >
              Decline
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Terms;
